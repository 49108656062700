<div style="overflow: hidden; height: 0;">
  <div class="container d-flex flex-column justify-content-center" #IDCardDiv id="idcard">
    <div class="row empty"></div>
      <div class="idCard mx-auto">
        <h4 class="heading">{{IDCardYear}} - {{IDCardYear+1}} Student Accident Insurance</h4>
        <p class="label">Insured Name: <span> {{IDCardStudent.student.firstName}}
            {{IDCardStudent.student.lastName}}</span></p>
        <p class="label" *ngIf="IDCardStudent.student.coverages.length>0">
          Effective Date: <span> {{IDCardStudent.student.coverages[0].effectiveDate| date:'MM/dd/yyyy'}} -
            {{IDCardStudent.student.coverages[0].terminationDate| date: 'MM/dd/yyyy'}}</span>
        </p>
        <p class="label" *ngIf="IDCardStudent.student.coverages.length>0">Coverage Purchased: <span
            *ngFor="let coverage of IDCardStudent.student.coverages" class="policyNumer" id=coveragePurchased> {{
            coverage.activity}} <br></span>
        </p>
        <p class="label" *ngIf="IDCardStudent.student.coverages.length>0">Policy Number: <span class="policyNumber"
            id=policyNumber> {{ IDCardStudent.student.coverages[0].voluntaryPolicyNo}}</span></p>
        <p class="label">Coverage Underwritten by: <span [innerText]="IDCardStudent.underWriter"> </span></p>
        <div class="admin row address">
          <p class="label">Claims Administrator:</p>
          <p class="companyName">Health Special Risk, Inc</p>
          <p class="address">8400 Belleview Drive, Suite 150, Plano, TX 75024</p>
          <img class="logo" src="assets/images/HSRlogo.jpg" alt="Unable to load image" />
          <p class="call">* * * Claim Questions? Call 1-866-409-5734 * * *</p>
        </div>
      </div>
  </div>
</div>
