<div id="payDiv" class="container">
    <div class="row">
        <div class="d-flex justify-content-center">
            <img src="./assets/images/icons/paymentsucess.jpg" class="icon" alt="Unable to load image">
        </div>
    </div>
    <div class="row new-title mt-2">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <p>Thank you for your purchase!</p>
        </div>
    </div>
    <div class="row textfile">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <p>
              Payment processed successfully.<br>
              Your order number is: <span class="policy">{{paymentDetails? paymentDetails.orderDetailID:''}}.</span>
            </p>
        </div>
    </div>


    <div class="row mb-5">
        <div class="col d-flex flex-wrap justify-content-center">
          <button type="button" class="bt mx-2 mb-3 animate-fading" id="downloadIDBtn" (click)="generateAndDownloadConsolidatedPDF()">Download Student ID Card</button>
          <button type="submit" class="btsave mx-2 mb-3" id="returnAccBtn" (click)="returnAccout()">Return to Account</button>
        </div>
    </div>
    <div id="idcards">
      <ng-container *ngFor="let IDCardStudent of studentIdCards, index as i">
        <div style="overflow: hidden; height: 0;">
          <div class="container d-flex flex-column justify-content-center idcard-size" #IDCardDiv [id]="'idcard'+i">
            <div class="row empty"></div>
            <div class="idCard mx-auto">
              <h4 class="heading">{{IDCardYear}} - {{IDCardYear+1}} Student Accident Insurance</h4>
              <p class="label">Insured Name: <span> {{IDCardStudent.student.firstName}}
                  {{IDCardStudent.student.lastName}}</span></p>
              <p class="label" *ngIf="IDCardStudent.student.coverages.length>0">
                Effective Date: <span *ngIf="paymentDetails && paymentDetails.effectiveDate && paymentDetails.termDate"> {{paymentDetails.effectiveDate | date:'MM/dd/yyyy'}} -
                  {{paymentDetails.termDate | date: 'MM/dd/yyyy'}}</span></p>
              <p class="label" *ngIf="IDCardStudent.student.coverages.length>0">Coverage Purchased: <span *ngFor="let coverage of IDCardStudent.student.coverages" class="policyNumer"
                  id=coveragePurchased> {{ coverage.activity}} <br></span></p>
              <p class="label" *ngIf="IDCardStudent.student.coverages.length>0">Policy Number: <span class="policyNumber"
                  id=policyNumber> {{ IDCardStudent.student.coverages[0].voluntaryPolicyNo}}</span></p>
              <p class="label">Coverage Underwritten by: <span [innerText]="underwriters.insCo"> </span></p>
              <div class="admin row address">
                <p class="label">Claims Administrator:</p>
                <p class="companyName">Health Special Risk, Inc</p>
                <p class="address">8400 Belleview Drive, Suite 150, Plano, TX 75024</p>
                <img class="logo" src="assets/images/HSRlogo.jpg" alt="Unable to load image"/>
                <p class="call">* * * Claim Questions? Call 1-866-409-5734 * * *</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
